
/**
 *Users list
 Users list and edit the user
 *
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */
import { Component, Vue } from "vue-property-decorator";

import ibomadeUserService from "@/services/ibomadeUserService";
import UserRolesOptions from "@/components/Users/UserRolesOptions.vue";
import AdminActions from "@/components/Users/AdminActions.vue";
import NewUserForm from "@/components/Users/NewUserForm.vue";
import TableHeader from "@/components/tableHeader.vue";
import { required } from "vuelidate/lib/validators";
import { Role } from "@/interface/RoleInterface";
import SingleUserView from "@/components/Users/SingleUserView.vue";
import { mapGetters } from "vuex";
import UserAccess from "@/model/User/UserAccess";
import { ActionMessage } from "@/model/Messages/statusMessage";

interface ModalUser {
  enabled: boolean;
  stateChangeReason: string;
}

@Component({
  name: "Users",
  components: {
    UserRolesOptions,
    AdminActions,
    NewUserForm,
    TableHeader,
    SingleUserView
  },
  computed: {
    ...mapGetters(["getUserAccess"])
  }
})
export default class Users extends Vue {
  readonly getUserAccess!: UserAccess;

  items: Array<object> = [];
  fields: Array<object> = [
    {
      key: "username",
      label: this.translateTableLabels("username"),
      sortable: true
    },
    {
      key: "firstName",
      label: this.translateTableLabels("firstName"),
      sortable: false
    },
    {
      key: "lastName",
      label: this.translateTableLabels("lastName"),
      sortable: true
    },
    { key: "email", label: this.translateTableLabels("email"), sortable: true },
    {
      key: "roles",
      label: this.translateTableLabels("roles"),
      sortable: false
    },
    {
      key: "status",
      label: this.translateTableLabels("status"),
      sortable: false
    },
    {
      key: "actions",
      label: this.translateTableLabels("actions"),
      sortable: false
    }
  ];
  dangerModal: boolean = false;
  modalUser: null | ModalUser = null;
  updateStatus: null | { class: string; msg: string } = null;
  userRole: null | object = null;
  openTextArea: boolean = false;
  modalUpdateStatus: null | ActionMessage = null;
  perPage: number = 10;
  currentPage: number = 1;
  loading = false;

  currentUserDetailsId = "";
  //validate
  validations() {
    return {
      modalUser: {
        stateChangeReason: {
          required
        }
      }
    };
  }
  /*=============================================
  =            Vuejs life cycles            =
  =============================================*/
  created() {
    this.loading = true;
    this.setUserList().then(() => {
      this.loading = false;
    });
  }

  /*=============================================
  =            computed propeerties            =
  =============================================*/
  get isUserEnable() {
    if (this.modalUser) {
      return this.modalUser.enabled;
    }
    return false;
  }
  get state() {
    if (this.modalUser && this.modalUser.stateChangeReason !== null) {
      return this.modalUser.stateChangeReason.length > 5;
    } else {
      return false;
    }
  }
  get rows() {
    return this.items.length;
  }

  /*=============================================
  =            methods                          =
  =============================================*/
  async setUserList() {
    this.items = await ibomadeUserService.getUserList();
  }
  resetModal() {
    this.dangerModal = false;
    this.modalUser = null;
    this.openTextArea = false;
  }
  checkUserRole(roles: Array<Role>) {
    return roles.length !== 0 ? roles[0].id : "user";
  }
  updateUserList(value: any) {
    this.updateStatus = null;
    ibomadeUserService.getUserList().then((data) => {
      this.openTextArea = false;
      this.items = data;
      this.updateStatus = value;
      this.resetModal();
    });
  }
  openModal(data: ModalUser) {
    this.modalUser = data;
    this.$bvModal.show("updateUser");
    this.updateStatus = null;
    this.openTextArea = false;
  }
  userStatusChange() {
    return (this.openTextArea = !this.openTextArea);
  }
  updateStatusNewUser(value: object) {
    this.updateUserList(value);
  }

  translateTableLabels(label: string) {
    return this.$t(`users.tableLabels.userListTable.${label}`);
  }

  handleUserRoleTranslation(role: string) {
    let haveTranslations = role in (this as any).$t("roles.labels");
    if (haveTranslations) return (this as any).$t(`roles.labels.${role}`);
    else return role;
  }

  openUserDetails(userId: string) {
    this.currentUserDetailsId = userId;
    this.$bvModal.show("singleUserDetails");
  }
}
