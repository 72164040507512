
/**
 * Single UserView
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import ibomadeUserService from "@/services/ibomadeUserService";
import ModelUser from "@/model/ModelUser";

@Component({
  name: "SingleUserView"
})
export default class SingleUserView extends Vue {
  @Prop({ type: String, default: "" }) userId!: string;

  /*----------  Local data  ----------*/
  modalUpdateStatus: null | object = null;
  user: null | ModelUser = null;
  loading = true;
  /*----------  Vue lifecycles  ----------*/
  // async created() {
  //   this.getUserDetails();
  // }

  /*----------  Computed  ----------*/
  get formatedUserInformation() {
    if (!this.user) return {};
    let {
      id,
      username,
      firstName,
      lastName,
      email,
      institution,
      phone,
      mobile,
      description,
      language,
      enabled,
      emailVerified,
      roles
    } = this.user;

    return {
      id,
      username,
      firstName,
      lastName,
      email,
      institution,
      phone,
      mobile,
      description,
      language,
      enabled,
      emailVerified,
      roles
    };
  }
  /*----------  methods  ----------*/
  handleUserRoleTranslation(role: string) {
    let haveTranslations = role in (this as any).$t("roles.labels");
    if (haveTranslations) return (this as any).$t(`roles.labels.${role}`);
    else return role;
  }
  async getUserDetails() {
    this.loading = true;

    if (this.userId) {
      let res = await ibomadeUserService.getUser(this.userId);
      this.user = new ModelUser(res.data);
    }
    this.loading = false;
  }

  resetUser() {
    this.user = null;
    this.loading = true;
  }
  closeModal() {
    this.resetUser();
    this.$bvModal.hide("singleUserDetails");
  }
}
